
























































import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component({ name: 'DocumentDialog' })
export default class DocumentDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('fileContent', { type: String, required: true })
  content!: string;

  @PropSync('fileType', { type: String, required: true })
  mime_type!: string;

  @PropSync('fileName', { type: String, required: true })
  name!: string;

  @PropSync('fileTitle', { type: String, required: true })
  title!: string;

  downloadDocument() {
    const linkSource = `data:${this.mime_type};base64,${this.content}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = this.name;
    downloadLink.click();
  }
}
