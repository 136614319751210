import i18n from '@/vue-app/plugins/i18n';

// Application

// Domain

export default class InquiriesViewModel {
  i18n_namespace = 'components.allianz-dashboard.inquiries';

  inquiries_list = [
    {
      title: i18n.tc(`${this.i18n_namespace}.pending_request`),
      content: i18n.tc(`${this.i18n_namespace}.pending_request_description`),
      icon: 'mdi-text-box',
      modal: 'show_requests_dialog',
    },
    {
      title: i18n.tc(`${this.i18n_namespace}.clarifications`),
      content: i18n.tc(`${this.i18n_namespace}.clarifications_description`),
      icon: 'mdi-magnify',
      modal: 'show_clarifications_dialog',
    },
    {
      title: i18n.tc(`${this.i18n_namespace}.policy_details`),
      content: i18n.tc(`${this.i18n_namespace}.policy_details_description`),
      icon: 'mdi-text-box',
      modal: 'show_policy_details_dialog',
    },
  ];

  modals: Record<string, boolean> = {
    show_requests_dialog: false,
    show_policy_details_dialog: false,
    show_clarifications_dialog: false,
  }

  showModal(modal: string) {
    this.modals[modal] = true;
  }
}
