








































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';

@Component({
  name: 'Clarifications',
  filters: { currencyFormat },
})
export default class Clarifications extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  i18n_namespace = 'components.allianz-dashboard.clarifications';

  phone = '55 5201 3000';

  mail = 'atención@allianz.com.mx';

  web = 'www.allianz.com.mx';
}
