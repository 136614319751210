

























import { Component, Vue } from 'vue-property-decorator';
import OptionsCard from '@/vue-app/components/allianz-dashboard/OptionsCard.vue';
import InquiriesViewModel from '@/vue-app/view-models/allianz-dashboard/inquiries-view-model';
import Requests from '@/vue-app/components/allianz-dashboard/allianz-actions/RequestsDialog.vue';
import PolicyDetails
  from '@/vue-app/components/allianz-dashboard/allianz-actions/PolicyDetailsDialog.vue';
import Clarifications
  from '@/vue-app/components/allianz-dashboard/allianz-actions/ClarificationsDialog.vue';

@Component({
  name: 'Inquiries',
  components: {
    OptionsCard,
    Requests,
    PolicyDetails,
    Clarifications,
  },
})
export default class Inquiries extends Vue {
  inquiries_view_model = Vue.observable(new InquiriesViewModel());
}
