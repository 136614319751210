




























































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import DocumentDialog
  from '@/vue-app/components/allianz-dashboard/allianz-actions/DocumentDialog.vue';
import PolicyDetailsViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/policy-details-view-model';

@Component({
  name: 'PolicyDetails',
  filters: { currencyFormat },
  components: { DocumentDialog },
})
export default class PolicyDetails extends Vue {
  @PropSync('isOpen', {
    type: Boolean,
    required: true,
    default: false,
  })
  synced_is_open!: boolean;

  policy_details_view_model = Vue.observable(new PolicyDetailsViewModel());

  created() {
    this.policy_details_view_model.initialize();
  }
}
