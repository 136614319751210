import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import { GetFileQuery } from '@/modules/my-investment/file/application/queries';
import {
  SearchCustomerDocumentsQuery,
} from '@/modules/my-investment/customer-documents/application/queries';

// Domain
import {
  CustomerDocumentEntity,
} from '@/modules/my-investment/customer-documents/domain/entities/customer-document-entity';
import {
  AllianzAccountEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/allianz-account-entity';
import State from '@/modules/my-investment/allianz/allianz-account/domain/state/state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class PolicyDetailsViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
  private readonly search_customer_documents_query!: SearchCustomerDocumentsQuery;

  @Inject(TYPES.MY_INVESTMENT_GET_FILE_QUERY)
  private readonly get_file_query!: GetFileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.policy_details';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  private readonly required_files = [
    'allianz_account_status',
    'allianz_proof_of_address',
    'allianz_issuance_request',
    'allianz_profiling_questionnaire',
    'allianz_customer_identification_format',
    'allianz_endorsement_of_alternatives',
    'allianz_charge_authorization',
    'allianz_distribution_annex',
    'allianz_sowos_moderate_endorsement_signed',
  ];

  files_info: Array<any> = [];

  is_loading = false;

  allianz_account: AllianzAccountEntity = new State().account;

  show_document_dialog = false;

  file_to_show = {
    name: '',
    content: '',
    mime_type: '',
  }

  file_id_selected = '';

  file_name_selected = '';

  last_account_numbers = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  getNameWithoutPoints = (filename: string) => (filename.replace(':', ''));

  loadAllianzAccount = async () => {
    try {
      this.allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (this.allianz_account) {
        this.last_account_numbers = Number(this.allianz_account.account.slice(-4));
        await this.loadCustomerDocuments();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  loadCustomerDocuments = async () => {
    try {
      const customer_documents = await this.search_customer_documents_query.execute();
      if (customer_documents.length) {
        this.fillDocumentsList(customer_documents);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_documents'));
    }
  }

  loadFile = async () => {
    try {
      this.file_to_show = await this.get_file_query.execute(this.file_id_selected);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_file'));
    }
  }

  fillDocumentsList(customer_documents: Array<CustomerDocumentEntity>) {
    this.required_files.forEach((file) => {
      customer_documents.forEach((doc) => {
        if (doc.document_type.name === file) {
          this.files_info.push({
            id: doc.customer_document_id,
            name: doc.document_type.name,
            value: this.translate(doc.document_type.name),
          });
        }
      });
    });
  }

  selectFile = async (file_id: string, file_name: string) => {
    this.is_loading = true;
    this.file_to_show = {
      name: '',
      content: '',
      mime_type: '',
    };
    this.file_id_selected = file_id;
    this.file_name_selected = file_name;
    await this.loadFile();
    this.show_document_dialog = true;
    this.is_loading = false;
  }

  initialize = async () => {
    await this.loadAllianzAccount();
    this.is_loading = false;
  }
}
